
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "@/store/index";
import { ApiResponseChatStatus, WemowChatPublicInterface } from "@/types";
import { removeOverlay, createOverlay, getCookie } from "@/utils";

import ChatWrap from "@/views/ChatWrap.vue";

export default defineComponent({
  components: {
    ChatWrap,
  },
  setup() {
    const store = useStore();

    const didLoadStatus = ref(false);
    const shouldShowChatWrapper = ref(false);

    const showChatWrapper = () => {
      if (didLoadStatus.value) {
        shouldShowChatWrapper.value = true;
        return true;
      }
    };
    const hideChatWrapper = () => {
      if (didLoadStatus.value) {
        shouldShowChatWrapper.value = false;
        return true;
      }
    };
    const checkAuthorization = async () => {
      console.log("checkAuthorization");
      if (didLoadStatus.value) {
        const isAuthenticated = await store.dispatch("checkAuth");
        store.commit("updateAppSettingsFields", {
          key: "isAuthenticated",
          value: !!isAuthenticated,
        });
        console.log("checkAuthorization result:", isAuthenticated);
        return !!isAuthenticated;
      }
      return false;
    };

    const hideContainerView = () =>
      store.commit("updateAppSettingsFields", {
        key: "showChatContainerView",
        value: false,
      });

    const showChatBubbleCTA = () =>
      store.commit("updateAppSettingsFields", {
        key: "floatingBubbleShowMessage",
        value: true,
      });

    const isShowingChatContainerView = computed(
      () => store.state.showChatContainerView
    );

    const hasActiveChatSession = computed(
      () =>
        store.state.chatSessionInitialized || !!getCookie("wemow_chat_session")
    );

    watch(isShowingChatContainerView, (value: boolean) => {
      if (value) {
        store.commit("updateAppSettingsFields", {
          key: "showExternalIndicator",
          value: false,
        });
        createOverlay();
      } else {
        removeOverlay();
      }
    });

    const openChat = () => {
      store.commit("updateAppSettingsFields", {
        key: "showChatContainerView",
        value: true,
      });
    };

    const getWemowChatInstance = (): WemowChatPublicInterface => ({
      initialized: store.state.chatAppInitializing,
      initializing: store.state.chatAppInitialized,
      status: store.state.chatAppStatus,
      hasActiveSession: hasActiveChatSession.value,
      message: store.state.chatAppStatusMessage,
      availableAgentName: store.state.chatAppAgentName,
      show: showChatWrapper,
      hide: hideChatWrapper,
      showMessage: showChatBubbleCTA,
      authorize: checkAuthorization,
      openChat: openChat,
      isOpen: () => isShowingChatContainerView.value,
    });

    const wemowChatInitialize = (
      cb: (res: WemowChatPublicInterface) => void
    ) => {
      if (store.state.chatAppInitializing || store.state.chatAppInitialized) {
        return;
      }

      store.commit("updateAppSettingsFields", {
        key: "chatAppInitializing",
        value: true,
      });

      store
        .dispatch("retrieveChatStatus")
        .then((chatStatus: ApiResponseChatStatus) => {
          const { status, message, availableAgentName } = chatStatus;

          store.commit("updateAppSettingsFields", {
            key: "chatAppStatus",
            value: status,
          });
          store.commit("updateAppSettingsFields", {
            key: "chatAppStatusMessage",
            value: message,
          });
          store.commit("updateAppSettingsFields", {
            key: "chatAppAgentName",
            value: availableAgentName,
          });

          didLoadStatus.value = true;
          cb(getWemowChatInstance());
        })
        .catch((e) => {
          cb({
            status: "error",
            message: e.message,
          });
        })
        .finally(() => {
          store.commit("updateAppSettingsFields", {
            key: "chatAppInitializing",
            value: false,
          });
        });
    };

    onMounted(() => {
      const w = window as any;
      const { __wemow_chat_config = {} } = w;
      console.log("__wemow_chat_config", __wemow_chat_config);
      w.wemowChatController = {};
      w.wemowChatController.init = wemowChatInitialize;
      w.wemowChatController.getInstance = getWemowChatInstance;
    });

    return {
      shouldShowChatWrapper,
      isShowingChatContainerView,
      hideContainerView,
      didLoadStatus,
    };
  },
});
