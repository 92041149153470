import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatWrap = _resolveComponent("ChatWrap")!

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.didLoadStatus && _ctx.shouldShowChatWrapper)
      ? (_openBlock(), _createBlock(_component_ChatWrap, {
          key: 0,
          isShowingChatContainerView: _ctx.isShowingChatContainerView,
          onHideContainerView: _ctx.hideContainerView
        }, null, 8, ["isShowingChatContainerView", "onHideContainerView"]))
      : _createCommentVNode("", true)
  ]))
}