import { getBackendUrl } from "@/utils";

export const getChatStatus = async (data: Record<string, any> = {}) => {
  const headers = new Headers({});
  const method = "POST";

  const path = `chat/status`;

  const response = await fetch(`${getBackendUrl()}/${path}`, {
    method,
    headers,
    body: JSON.stringify(data),
  });

  return response.json();
};
