
import {
  defineComponent,
  computed,
  ref,
  watch,
  nextTick,
  onMounted,
} from "vue";
import { useStore } from "@/store/index";
import SingleMessage from "./content/SingleMessage.vue";

export default defineComponent({
  components: { SingleMessage },
  setup() {
    const store = useStore();
    const messages = computed(() => store.state.chatMessages);
    const agentTyping = computed(() => store.state.chatSessionAgentTyping);

    const chatMainContent = ref(null);

    const scrollToBottomOfContent = () => {
      nextTick(() => {
        if (chatMainContent.value) {
          const el = chatMainContent.value as any;
          el.scrollTop = el.scrollHeight + 100;
        }
      });
    };

    watch(
      () => messages.value.length,
      (_count) => scrollToBottomOfContent()
    );

    onMounted(() => scrollToBottomOfContent());

    return { messages, agentTyping, chatMainContent };
  },
});
