import { State } from "./state";

export const isDarkMode = (state: State): boolean => {
  return state.darkMode;
};
export const isAppLoading = (
  state: State
): { loading: boolean; reason?: string } => {
  if (state.loadingGet) {
    return {
      loading: true,
      reason: "loadingGet",
    };
  }
  return {
    loading: false,
  };
};

export const appErrorMessage = (state: State): string => {
  return state.globalError;
};

export const isInitializingChatSession = (state: State): boolean => {
  return state.chatSessionInitializing;
};
export const isChatSessionInitialized = (state: State): boolean => {
  return state.chatSessionInitialized;
};

export const getChatSession = (state: State): boolean => {
  return state.chatSession;
};
