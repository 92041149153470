
import { defineComponent, computed } from "vue";
import {
  format,
  formatDistance,
  isAfter,
  addMinutes,
  parseISO,
} from "date-fns";

export default defineComponent({
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props) {
    const formatDate = (date: string | Date) => {
      const theDate = typeof date === "string" ? parseISO(date) : date;
      if (isAfter(theDate, addMinutes(new Date(), 60))) {
        return format(theDate, "mm/d mm:ss");
      }
      return `${formatDistance(theDate, new Date())} ago`;
    };

    const messageClassList = computed(() => {
      if (["SYSTEM", "AGENT"].includes(props.message.participantRole)) {
        return ["bg-primary", "text-white"];
      }
      return ["bg-gray-100", "border", "border-gray-200"];
    });

    const messageWrapClassList = computed(() => {
      if (["SYSTEM", "AGENT"].includes(props.message.participantRole)) {
        return ["justify-start"];
      }
      return ["justify-end"];
    });

    const getEventText = computed(() => {
      switch (props.message.contentType) {
        case "application/vnd.amazonaws.connect.event.participant.joined":
          return `${props.message.displayName} joined the chat`;
        case "application/vnd.amazonaws.connect.event.participant.left'":
          return props?.message?.participantRole === "CUSTOMER"
            ? ""
            : `${props.message.displayName} left the chat`;
        case "application/vnd.amazonaws.connect.event.chat.ended":
          return "Chat ended";
      }
      return "";
    });

    const getDisplayName = computed(() => {
      if (props.message.participantRole === "SYSTEM") {
        return "";
      }
      if (props.message.participantRole === "CUSTOMER") {
        return "You";
      }
      return props.message.displayName;
    });

    return {
      messageClassList,
      messageWrapClassList,
      formatDate,
      getEventText,
      getDisplayName,
    };
  },
});
