
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store/index";
import { getCookie, eraseCookie } from "@/utils";

import AppLoading from "@/components/AppLoading.vue";
import ChatFloatingBubble from "@/components/ChatFloatingBubble.vue";
import ChatNotInitialized from "@/views/ChatNotInitialized.vue";
import ChatContainer from "@/views/ChatContainer.vue";

export default defineComponent({
  components: {
    AppLoading,
    ChatNotInitialized,
    ChatContainer,
    ChatFloatingBubble,
  },
  props: {
    isShowingChatContainerView: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props: any, context) {
    const store = useStore();
    const message = ref("");

    onMounted(() => {
      store.commit("initializeChatSession");

      if (getCookie("wemow_chat_session")) {
        const cook = getCookie("wemow_chat_session");
        if (cook) {
          try {
            const parsedCookie = JSON.parse(cook);
            store
              .dispatch("restartCustomerSession", parsedCookie)
              .then(() => {
                store.dispatch("getChatTranscripts").then((messages) => {
                  console.log("getChatTranscripts", messages);
                });
              })
              .catch(() => {
                eraseCookie("wemow_chat_session");
              });
          } catch (error) {
            eraseCookie("wemow_chat_session");
          }
        }
      }
    });

    const loadingChatSession = computed(
      () => store.getters.isInitializingChatSession
    );
    const chatSessionReady = computed(
      () => store.getters.isChatSessionInitialized
    );

    const containerStyleList = computed(() => {
      if (chatSessionReady.value) {
        return { width: "390px", minHeight: "580px" };
      }
      return { width: "400px", maxHeight: "600px", minHeight: "320px" };
    });

    const hideContainerView = () =>
      store.commit("updateAppSettingsFields", {
        key: "showChatContainerView",
        value: false,
      });

    const closeWrap = () => context.emit("hideContainerView");

    return {
      message,
      closeWrap,
      loadingChatSession,
      chatSessionReady,
      hideContainerView,
      containerStyleList,
    };
  },
});
