import { getBackendUrl } from "@/utils";

export const checkAuthorization = async (data: Record<string, any> = {}) => {
  const headers = new Headers();
  const method = "POST";
  const path = `auth-check`;

  let authenticated = false;

  if (!localStorage.getItem("id_token")) {
    return false;
  }

  try {
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("id_token")}`
    );

    headers.append("Access-Control-Request-Method", "POST");
    headers.append("Access-Control-Request-Headers", "Authorization");
    headers.append("Access-Control-Allow-Origin", "*");

    await fetch(`${getBackendUrl()}/${path}`, {
      method,
      headers,
      body: JSON.stringify(data),
    });

    authenticated = true;
  } catch (error) {
    /* Do Nothing */
  }
  return authenticated;
};
