import { getBackendUrl } from "@/utils";

export const startChat = async (data: Record<string, any> = {}) => {
  const headers = new Headers({});
  const method = "POST";

  let path = `chat/start`;

  if (localStorage.getItem("id_token")) {
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("id_token")}`
    );
    path = `connect/start-chat-user`;
  }

  const response = await fetch(`${getBackendUrl()}/${path}`, {
    method,
    headers,
    body: JSON.stringify(data),
  });

  return response.json();
};
