import { AppSettingsState } from "@/types";

export const appSettingsState: AppSettingsState = {
  darkMode: true,
  isAuthenticated: false,
  userEmailAddress: "",
  userDisplayName: "",
  chatAppInitialized: false,
  chatAppInitializing: false,
  chatAppStatus: "",
  chatAppAgentName: "",
  chatAppStatusMessage: "",
  showChatApp: false,
  chatSession: null,
  chatSessionEnded: false,
  chatSessionInitialized: false,
  chatSessionInitializing: false,
  chatSessionConnectionEstablished: false,
  floatingBubbleShowMessage: false,
  chatSessionAgentTyping: false,
  chatSessionAgentTypingTimeout: null,
  showChatContainerView: false,
  chatMessages: [],
  showExternalIndicator: false,
  chatSessionActiveAgentName: "",
  loadingGet: false,
  globalError: "",
};

export interface State extends AppSettingsState {
  loadingGet: boolean;
}

export const state: State = {
  ...appSettingsState,
};
