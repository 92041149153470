
import { defineComponent, computed } from "vue";
import { useStore } from "@/store/index";
export default defineComponent({
  setup() {
    const store = useStore();
    const agentName = computed(() => store.state.chatSessionActiveAgentName);

    return { agentName };
  },
});
