import { startChat } from "@/services/startChat";
import { getChatStatus } from "@/services/getChatStatus";
import { checkAuthorization } from "@/services/checkAuth";
import { setCookie, eraseCookie } from "@/utils";

import {
  ApiResponseStartChat,
  IChatStartPayload,
  ApiResponseChatStatus,
} from "@/types";

export const checkAuth = async () => {
  return await checkAuthorization();
};

export const startChatGetToken = async (
  { state, commit, getters }: any,
  { emailAddress, firstName, accountId }: IChatStartPayload
): Promise<ApiResponseStartChat | undefined> => {
  if (state.loadingGet) {
    return;
  }

  commit("updateAppSettingsFields", {
    key: "chatSessionInitializing",
    value: true,
  });

  try {
    const { data = {} } = await startChat({
      emailAddress,
      firstName,
      accountId,
    });
    const startChatResult = data as ApiResponseStartChat;
    // commit("setResultsResults", startChatResult);
    return startChatResult;
  } catch (error) {
    const errorMessage =
      error instanceof Error && error.message
        ? error.message
        : "There was an error loading the token. Try again.";
    commit("updateAppSettingsFields", {
      key: "globalError",
      value: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const retrieveChatStatus = async ({
  state,
  commit,
  getters,
}: any): Promise<ApiResponseChatStatus | undefined> => {
  try {
    const { data = {} } = await getChatStatus();
    const startChatResult = data as ApiResponseChatStatus;
    commit("updateAppSettingsFields", {
      key: "chatAppStatus",
      value: startChatResult.status,
    });
    commit("updateAppSettingsFields", {
      key: "chatAppStatusMessage",
      value: startChatResult.message,
    });
    commit("updateAppSettingsFields", {
      key: "chatAppAgentName",
      value: startChatResult.availableAgentName,
    });
    //

    return startChatResult;
  } catch (error) {
    const errorMessage =
      error instanceof Error && error.message
        ? error.message
        : "There was an error loading the status. Try again.";
    commit("updateAppSettingsFields", {
      key: "globalError",
      value: errorMessage,
    });
    throw new Error(errorMessage);
  }
};

export const getChatTranscripts = async ({
  state,
}: any): Promise<any | undefined> => {
  const response = await state.chatSession.getTranscript({
    scanDirection: "BACKWARD",
    sortOrder: "ASCENDING",
    maxResults: 15,
  });

  console.log("response.data.Transcript", response.data.Transcript);
  return response.data.Transcript;
};

export const startCustomerSession = async (
  { state, commit }: any,
  startChatResult: ApiResponseStartChat
) => {
  console.log("startCustomerSession");
  commit("setCustomerSession", startChatResult);

  try {
    await state.chatSession.connect();
    commit("updateAppSettingsFields", {
      key: "chatSessionInitialized",
      value: true,
    });
    setCookie({
      name: "wemow_chat_session",
      value: JSON.stringify(startChatResult),
      days: 3,
    });
  } catch (error) {
    commit("updateAppSettingsFields", {
      key: "chatSessionInitialized",
      value: false,
    });
    console.log("Unsuccessful connection " + JSON.stringify(error));
  }
};

export const restartCustomerSession = async (
  { state, dispatch, commit }: any,
  startChatResult: ApiResponseStartChat
): Promise<any | undefined> => {
  await dispatch("startCustomerSession", startChatResult);
  const response = await state.chatSession.getTranscript({
    scanDirection: "BACKWARD",
    sortOrder: "ASCENDING",
    maxResults: 50,
  });

  console.log("response.data.Transcript", response.data.Transcript);

  for (const message of response.data.Transcript) {
    commit("_onMessage", { data: message });
  }

  return response.data.Transcript;
};

export const resetCustomerSession = async (
  { dispatch, commit }: any,
  startChatPayload?: IChatStartPayload
): Promise<any | undefined> => {
  eraseCookie("wemow_chat_session");
  console.log("??", startChatPayload);
  commit("updateAppSettingsFields", {
    key: "chatSessionInitialized",
    value: false,
  });

  if (!startChatPayload) {
    return;
  }

  commit("updateAppSettingsFields", {
    key: "chatSessionInitializing",
    value: true,
  });

  try {
    const response = await dispatch("startChatGetToken", startChatPayload);

    await dispatch("startCustomerSession", response);

    commit("updateAppSettingsFields", {
      key: "chatSessionInitialized",
      value: true,
    });
  } finally {
    commit("updateAppSettingsFields", {
      key: "chatSessionInitializing",
      value: false,
    });
  }
};
