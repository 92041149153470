
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store/index";
import { eraseCookie, validateEmail } from "@/utils";

export default defineComponent({
  setup() {
    const store = useStore();

    const firstName = ref("");
    const emailAddress = ref("");
    const didAutoFill = ref(false);

    const isAuthenticated = computed(() => store.state.isAuthenticated);

    onMounted(() => {
      if (store.state.userEmailAddress) {
        emailAddress.value = store.state.userEmailAddress;
        didAutoFill.value = true;
      }
      if (store.state.userDisplayName) {
        firstName.value = store.state.userDisplayName;
        didAutoFill.value = true;
      }
    });

    const onClickStartChat = async () => {
      const isAppLoggedIn = store.state.isAuthenticated;
      if (isAppLoggedIn) {
        await store.dispatch("resetCustomerSession", {});
      } else {
        await store.dispatch("resetCustomerSession", {
          emailAddress: emailAddress.value,
          firstName: firstName.value,
        });
      }
    };

    const onClickForgetMe = () => {
      firstName.value = "";
      emailAddress.value = "";
      didAutoFill.value = false;
      eraseCookie("wemow_chat_participant");
    };

    const isFormValid = computed(() => {
      if (store.state.isAuthenticated) {
        return true;
      }
      if (!firstName.value || firstName.value.length < 1) {
        return false;
      }
      if (!emailAddress.value || emailAddress.value.length < 4) {
        return false;
      }
      return validateEmail(emailAddress.value);
    });

    const chatStatus = computed(() => {
      return {
        status: store.state.chatAppStatus,
      };
    });

    return {
      chatStatus,
      isAuthenticated,
      onClickStartChat,
      firstName,
      emailAddress,
      didAutoFill,
      isFormValid,
      onClickForgetMe,
    };
  },
});
