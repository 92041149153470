import { withModifiers as _withModifiers, withKeys as _withKeys, vModelText as _vModelText, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-4 relative" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "mt-2 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode("textarea", {
        class: "\n          w-full\n          border-gray-300 border\n          focus:border-gray-400\n          bg-gray-200\n          py-2\n          px-3\n          rounded-sm\n          placeholder-gray-500\n          text-gray-700\n        ",
        type: "text",
        rows: "4",
        onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSendTyping && _ctx.onSendTyping(...args))),
        onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.sendChatMessage && _ctx.sendChatMessage(...args)), ["prevent"]), ["enter"])),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.message = $event))
      }, null, 544), [
        [_vModelText, _ctx.message]
      ])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_app_button, {
        class: "w-full",
        text: "Send chat",
        onClick: _ctx.sendChatMessage,
        isDisabled: _ctx.message.length === 0
      }, null, 8, ["onClick", "isDisabled"])
    ])
  ]))
}