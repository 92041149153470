
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return {};
  },
});
