
import { defineComponent, ref } from "vue";
import { useStore } from "@/store/index";

export default defineComponent({
  setup() {
    const store = useStore();
    const message = ref("");

    const onSendTyping = () => store.commit("sendTypingEvent");

    const sendChatMessage = () => {
      if (message.value.length > 0) {
        store.commit("sendChatMessage", message);
        message.value = "";
      }
    };
    return { message, onSendTyping, sendChatMessage };
  },
});
