
import { computed, defineComponent } from "vue";
export default defineComponent({
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
      validator: (val: string) =>
        ["primary", "secondary", "text"].includes(val),
    },
    size: {
      type: String,
      default: "normal",
      validator: (val: string) => ["small", "normal"].includes(val),
    },
    icon: {
      type: String,
      default: "primary",
      validator: (val: string) => ["download"].includes(val),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const buttonClasses = computed(() =>
      Object.assign(
        {},
        {
          "wemow-chat-inline-flex": true,
          "justify-center": true,
          "items-center": true,
          "py-4": props.size === "normal",
          "px-4": props.size === "normal",
          "py-1 px-2": props.size === "small",
          "border border-transparent": true,
          "shadow-sm": true,
          "text-sm": true,
          "font-medium": true,
          "rounded-md": true,
          "bg-primary hover:bg-green-700 text-white": props.type === "primary",
          "bg-gray-600 hover:bg-gray-700 text-gray-100":
            props.type === "secondary",
          "bg-gray-200 hover:bg-gray-400 text-gray-500 hover:text-gray-700":
            props.type === "muted",
          "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500":
            true,
          "cursor-not-allowed opacity-50": props.isDisabled,
        }
      )
    );
    return { buttonClasses };
  },
});
