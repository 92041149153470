
import { defineComponent, computed } from "vue";
import { useStore } from "@/store/index";
// import { useGtag } from "vue-gtag-next";
export default defineComponent({
  setup() {
    const store = useStore();
    const closeFloatingMessage = () =>
      store.commit("updateAppSettingsFields", {
        key: "floatingBubbleShowMessage",
        value: false,
      });

    const onClickOpenChat = () =>
      store.commit("updateAppSettingsFields", {
        key: "showChatContainerView",
        value: true,
      });
    return { closeFloatingMessage, onClickOpenChat };
  },
});
