
import { defineComponent, computed } from "vue";
import { useStore } from "@/store/index";

export default defineComponent({
  setup() {
    const store = useStore();
    const outerDivClassList = computed(() => {
      return [""];
    });

    const onClickIconBubble = () => {
      if (store.state.showChatContainerView) {
        store.commit("updateAppSettingsFields", {
          key: "showChatContainerView",
          value: false,
        });
      } else {
        store.commit("updateAppSettingsFields", {
          key: "showChatContainerView",
          value: true,
        });
      }
    };

    const showExternalIndicator = computed(
      () => store.state.showExternalIndicator
    );
    return { outerDivClassList, onClickIconBubble, showExternalIndicator };
  },
});
