import { AppSettingsKeys } from "@/types";
import { Ref } from "vue";
import { State } from "./state";
import { ApiResponseStartChat } from "@/types";

export const initializeChatSession = (state: State) => {
  const w = window as any;
  w.connect.ChatSession.setGlobalConfig({
    region: "us-west-2",
  });
};

export const setCustomerSession = (
  state: State,
  startChatResult: ApiResponseStartChat
) => {
  console.log("setCustomerSession");
  const w = window as any;
  state.chatSessionEnded = false;

  state.chatSession = Object.freeze(
    w.connect.ChatSession.create({
      chatDetails: startChatResult,
      type: "CUSTOMER",
    })
  );
  state.chatSession.onConnectionEstablished((data: any) =>
    _onConnectionEstablished(state, data)
  );

  state.chatSession.onMessage((message: any) => _onMessage(state, message));

  state.chatSession.onTyping((typingEvent: any) =>
    _onTyping(state, typingEvent)
  );

  state.chatSession.onConnectionBroken((data: any) =>
    _onConnectionBroken(state, data)
  );
};

export const _onConnectionEstablished = (state: State, data: any) => {
  console.log("Established!", data);
  state.chatSessionConnectionEstablished = true;
};

export const _onMessage = (state: State, message: any) => {
  console.log("Received message _onMessage: ", message);
  if (message && message?.data?.ParticipantRole === "AGENT") {
    if (
      message &&
      message?.data?.ContentType ===
        "application/vnd.amazonaws.connect.event.participant.joined"
    ) {
      state.chatSessionActiveAgentName = message?.data?.DisplayName || "";
    }

    if (!state.showChatContainerView) {
      state.showExternalIndicator = true;
    }

    if (state.chatSessionAgentTypingTimeout) {
      clearTimeout(state.chatSessionAgentTypingTimeout);
    }
    state.chatSessionAgentTyping = false;
  }
  state.chatMessages.push({
    participantRole: message.data.ParticipantRole,
    displayName: message.data.DisplayName,
    type: message.data.Type,
    contentType: message.data.ContentType,
    content: message.data.Content,
    time: message.data.AbsoluteTime,
  });
};

export const setAllMessages = (state: State, messages: any[]) => {
  if (Array.isArray(messages)) {
    state.chatMessages = messages
      .filter((m) => m)
      .map((message: any) => ({
        participantRole: message?.ParticipantRole,
        displayName: message?.DisplayName,
        type: message?.Type,
        contentType: message?.ContentType,
        content: message?.Content,
        time: message?.AbsoluteTime,
      }));
  }
};

export const _onTyping = (state: State, typingEvent: any) => {
  console.log("Received typing event: " + JSON.stringify(typingEvent));
  if (typingEvent && typingEvent?.data?.ParticipantRole === "AGENT") {
    if (state.chatSessionAgentTypingTimeout) {
      clearTimeout(state.chatSessionAgentTypingTimeout);
    }
    state.chatSessionAgentTyping = true;
    state.chatSessionAgentTypingTimeout = setTimeout(() => {
      state.chatSessionAgentTyping = false;
    }, 5000);
  }
};
export const _onConnectionBroken = (state: State, data: any) => {
  console.log("Connection broken.", data);
  state.chatSessionConnectionEstablished = false;
};

export const sendChatMessage = (state: State, message: Ref<string>) => {
  console.log("Clicked with message " + message.value);
  state.chatSession.controller.sendMessage({
    message: message.value,
    contentType: "text/plain",
  });
};

export const endChat = (state: State) => {
  state.chatSession.controller.disconnectParticipant();
  state.chatSessionEnded = true;
};

export const sendTypingEvent = (state: State) => {
  state.chatSession.controller.sendEvent({
    contentType: "application/vnd.amazonaws.connect.event.typing",
  });
};

export const updateAppSettingsFields = (
  state: State,
  {
    key,
    value,
  }: {
    key: keyof Pick<State, AppSettingsKeys>;
    value: string | number | boolean;
  }
): void => {
  switch (key) {
    case "chatSessionActiveAgentName":
    case "chatAppStatus":
    case "chatAppAgentName":
    case "chatAppStatusMessage":
    case "userEmailAddress":
    case "userDisplayName":
    case "globalError":
      if (typeof value === "string") state[key] = value;
      break;
    case "isAuthenticated":
    case "chatAppInitialized":
    case "chatAppInitializing":
    case "darkMode":
    case "showChatApp":
    case "loadingGet":
    case "showChatContainerView":
    case "floatingBubbleShowMessage":
    case "chatSessionInitializing":
    case "chatSessionInitialized":
    case "chatSessionAgentTyping":
    case "showExternalIndicator":
    case "chatSessionEnded":
      if (typeof value === "boolean") state[key] = value;
      break;
    default:
      console.warn(`Saving unknown key ${key}`);
      break;
  }

  const backupSetting = ["darkMode"];
  if (backupSetting.includes(key)) {
    localStorage.setItem(key, value ? "1" : "0");
  }
};
