import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "fixed bottom-0 right-0",
  style: {"z-index":"99999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FloatingMessage = _resolveComponent("FloatingMessage")!
  const _component_FloatingIcon = _resolveComponent("FloatingIcon")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.showMessage)
      ? (_openBlock(), _createBlock(_component_FloatingMessage, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_FloatingIcon)
  ]))
}