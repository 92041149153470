import { createApp } from "vue";
import App from "./App.vue";
import { store, key } from "./store";

import "./assets/style.css";
import AppButton from "./components/AppButton.vue";

import VueGtag from "vue-gtag-next";

const app = createApp(App);

const { VUE_APP_GTAG_ID } = process.env;

const head = document.head;
const link = document.createElement("link");
link.type = "text/css";
link.rel = "stylesheet";
link.href = "https://assets.wemow.com/packages/chat-ui/wemowChatUI.css";
head.appendChild(link);

app.use(store, key);

if (VUE_APP_GTAG_ID) {
  app.use(VueGtag, {
    property: {
      id: VUE_APP_GTAG_ID,
    },
  });
}

app.component("AppButton", AppButton);
app.mount("#wemow-chat-app");
