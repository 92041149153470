
import { defineComponent, computed } from "vue";
import { useStore } from "@/store/index";
import FloatingMessage from "./floating-bubble/FloatingMessage.vue";
import FloatingIcon from "./floating-bubble/FloatingIcon.vue";

export default defineComponent({
  components: { FloatingMessage, FloatingIcon },
  setup() {
    const store = useStore();
    const showMessage = computed(() => store.state.floatingBubbleShowMessage);
    return { showMessage };
  },
});
