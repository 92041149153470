
import { computed, defineComponent } from "vue";
import { useStore } from "@/store/index";
import { getChatParticipant } from "@/utils";
// import { useGtag } from "vue-gtag-next";

import ChatHeader from "@/components/ChatHeader.vue";
import ChatInput from "@/components/ChatInput.vue";
import ChatContent from "@/components/ChatContent.vue";
export default defineComponent({
  components: { ChatHeader, ChatInput, ChatContent },
  setup(_props, context) {
    const store = useStore();
    const endChat = () => store.commit("endChat");

    const startNewChat = async () => {
      const chatParticipant = getChatParticipant();
      await store.dispatch("resetCustomerSession", chatParticipant?.data);
    };

    const closeAndResetChat = async () => {
      console.log(">??");
      context.emit("close");
      await store.dispatch("resetCustomerSession", undefined);
    };

    const chatEnded = computed(() => store.state.chatSessionEnded);
    return { endChat, chatEnded, startNewChat, closeAndResetChat };
  },
});
