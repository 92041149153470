import { IChatStartPayloadAnonymous, IChatStartPayloadUser } from "@/types";
export const setCookie = ({
  name,
  value,
  days,
}: {
  name: string;
  value: string;
  days: number;
}): void => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name: string): string | null => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name: string): void => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const validateEmail = (email: string) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getChatParticipant = ():
  | null
  | {
      type: "user";
      data: IChatStartPayloadUser;
    }
  | {
      type: "anonymous";
      data: IChatStartPayloadAnonymous;
    } => {
  const w = window as any;
  if (w.__wemow_chat_config?.accountId) {
    const accountId = w.__wemow_chat_config.accountId;
    return {
      type: "user",
      data: { accountId },
    };
  }

  const fromCookie = getCookie("wemow_chat_participant");
  if (fromCookie) {
    const [firstName, emailAddress] = fromCookie.split("|");
    return {
      type: "anonymous",
      data: { emailAddress, firstName },
    };
  }
  return null;
};

export const removeOverlay = (): void => {
  const overlay = document.getElementById("wemow-chat-overlay");
  if (overlay) {
    document.body.removeChild(overlay);
  }
  document.body.classList.remove("wemow-chat-visible");
};

export const createOverlay = (): void => {
  const overlay = document.createElement("div");
  overlay.setAttribute("id", "wemow-chat-overlay");
  overlay.setAttribute(
    "style",
    "z-index:9999998;width:100%;height:100%;position:absolute;background:#000000;opacity:0.4;top:0;left:0"
  );
  document.body.appendChild(overlay);
  document.body.classList.add("wemow-chat-visible");
};

export const getBackendUrl = () => {
  const w = window as any;
  const applicationEnvironment = w.__wemowApplicationEnvironment;
  switch (applicationEnvironment) {
    case "production":
      return "https://connect.wemow.com";
    case "staging":
    case "development":
      return "https://staging-connect.wemow.com";
  }
  return "";
};
